@import url(../../../../styles/variables.less);

.job_apply_question {
  display: block;
  padding: 0 16px 16px 16px;

  .job_apply_question_question {
    display: block;
    font-weight: 500;
    font-size: 1.2em;
    padding-bottom: 16px;
  }

  .job_apply_question_answer {
    display: block;
    padding-left: 16px;
  }
}

.job_apply_footer {
  display: flex;

  > div {
    flex-grow: 1;
  }

  > button {
    width: 100px;
  }
}

.job_apply_footer_button {
  justify-content: end;
}

.job_apply_check_group {
  .ant_checkbox_group_item {
    display: block;
    height: 30px;
    line-height: 30px;
  }
}

.job_apply_content {
  min-height: 250px;
  display: flex;
  flex-direction: column;

  .job_apply_content_body {
    flex-grow: 1;

    h2 {
      text-align: center;
    }
  }

  .ant_upload {
    margin-top: 20px;
    min-height: 100px;
  }
}

.job_apply_cv {
  display: flex;
  flex-direction: column;
  padding-left: 3px;

  > div {
    border: 1px solid #eee;
    padding: 16px 24px;
    border-radius: 4px;
    margin-bottom: 8px;
    // transition: all 0.3s ease;
  }

  .job_apply_cv_selected {
    border-color: @primary-color;
    box-shadow: 0 0 4px 0 @primary-color;
  }

  .job_apply_error {
    padding-left: 30px;
    font-size: 0.9em;
  }
}

.job_apply_summary_title {
  color: @primary-color;
  font-weight: bold;
  padding-left: 24px;
  font-size: 1.2em;
}

.job_apply_cv_existing_row {
  display: flex;
  line-height: 30px;

  .spacer {
    flex-grow: 1;
  }

  > a {
    margin-left: 10px;
  }
}

.job_apply_cv_uploaded_row {
  padding: 10px 0 0 30px;
}

.job_apply_summary_question {
  padding-left: 24px;

  .job_apply_summary_qq {
    margin-top: 16px;
  }

  .job_apply_summary_qa {
    margin-top: 8px;
    margin-left: 20px;
  }
}

.jobApplyStep {
  > div {
    display: flex;
    align-items: center;
  }
}

.jobApplyStepcv {
  > div {
    display: flex;
    @media (min-width: 576px) {
      align-items: center;
    }
  }
}
.requiredastrix{
  color: #ff4d4f;
  font-size: 16px;
  font-family: SimSun,sans-serif;
  line-height: 1;
}
.mcqSingleRadio{
  display: flex;
  height: 32px;
  line-height: 32px;

  .ant-radio{
    margin-top: 0;
  }
}
