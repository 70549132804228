@import '../../styles/appvar';

.titleLevel1 {
  font-size: 56px !important;
  font: normal normal bold 56px/59px Roboto;
  letter-spacing: 0.28px;
  margin-bottom: 0 !important;
  margin-left: -2px;
  @media (max-width: @screen-sm-max) {
    font-size: 34px !important;
    letter-spacing: 0;
  }
}

.titleLevel2 {
  font-size: 39px !important;
  font: normal normal bold 56px/59px Roboto;
  letter-spacing: 0.28px;
  @media (max-width: @screen-sm-max) {
    font-size: 40px !important;
  }
}

.titleLevel3 {
  font-size: 28px !important;
  font: normal normal bold 56px/59px Roboto;
  letter-spacing: 0.28px;
  @media (max-width: @screen-sm-max) {
    font-size: 20px !important;
  }
}

//.text {
//  font-size: 20px;
//  @media(max-width: @screen-sm-max) {
//    font-size: 18px;
//  }
//}

.textWithTie {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  > div:first-child {
    margin-right: 10px;
  }


  .textWithTieText {
    font-size: 18px;
    color: #5B6272;
  }

}

.linkWithIcon {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  overflow: hidden;
  width: 50%;

  > div:first-child {
    margin-right: 10px;
  }

  > * {
    color: #202124 !important;
    overflow: hidden;
    text-overflow: ellipsis;

    > a {
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #202124 !important;
    }

  }

  .textWithIconText {
    font-size: 18px;
    color: #5B6272;
  }
}

.linkWithIcon:hover {
  color: #04914D !important;

  > * {
    color: #04914D !important;

    > a {
      color: #04914D !important;
    }

  }
}
