@import './themes/default';

@base-url: 'https://sezenta-share.s3.amazonaws.com';
@primary-color: #009E52;
@lite-color: #ACACAC;
@danger-color: #FC646A;
//@screen-md-max: 991px;
//@screen-md-min: 860px;
//@screen-sm-max: 767px;
//@screen-xs-max: 575px;
//@screen-lg-min: 1300px;
//@screen-lg-min-x: 1200px;
//@screen-md-max-x: 1080px;
//@screen-lg-max: 1590px;
//@screen-lg: 992px;

@app-padding-xs: 4px;
@app-padding-sm: 8px;
@app-padding-md: 16px;
@app-padding-lg: 20px;
@app-padding-xl: 32px;
@app-padding-xxl: 65px;
@app-padding-xxxl: 100px;

@app-text-color-secondary: #6c6c6c;

@app-gap-sm: 4px;
@app-gap-md: 8px;
@app-gap-lg: 20px;
@app-gap-xl: 32px;

@light-gray: #ECEFF1;
@dark-gray: #5E6060;
@candidate-width: 1600px;
@candidate-min-width: unset;

@app-font-size-title1: 50px;
@app-font-size-title2: 40px;
@app-font-size-title3: 25px;
@app-font-size-title4: 20px;
@app-font-size-title5: 17px;
@app-font-size-title6: 16px;
@app-font-size-title7: 14px;

@app-font-size-text1: 24px;
@app-font-size-text2: 20px;
@app-font-size-text3: 17px;
@app-font-size-text4: 16px;
@app-font-size-text5: 14px;
@app-font-size-text6: 13px;
@app-font-size-text7: 12px;
@app-font-size-text8: 11px;
@app-font-size-text9: 10px;


.font1Title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.font1Lite {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.font2Lite {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  color: #acacac;
}

//h1 {
//  font-family: 'Poppins', sans-serif;
//  font-weight: 600;
//  font-size: 48px;
//  position: relative;
//}
//
//h2 {
//  font-family: 'Poppins', sans-serif;
//  font-weight: 600;
//  font-size: 28px;
//  position: relative;
//}

.fs72 {
  font-size: 72px;
}

.fs22 {
  font-size: 22px;
}

.fs16 {
  font-size: 16px;
}

.fs14 {
  font-size: 14px;
}

//@screen-xs-min: 480px;
//@screen-xs: 480px;
//@screen-xs-max: 575px;
//@screen-sm-min: 576px;
//@screen-sm: 576px;
//@screen-sm-max: 767px;
//@screen-md-min: 768px;
//@screen-md: 768px;
//@screen-md-max: 991px;
//@screen-lg-min: 992px;
//@screen-lg: 992px;
//@screen-lg-max: 1199px;
//@screen-xl-min: 1200px;
//@screen-xl: 1200px;
//@screen-xl-max: 1599px;
//@screen-xxl-min: 1600px;
//@screen-xxl: 1600px;
