@import '../../../styles/appvar';

.profileStrength {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 30px;
  position: relative;
}

.profileStrengthBar {
  width: 100%;
  height: 10px;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  background: #ECEFF1 0 0 no-repeat padding-box;
  box-shadow: inset 0 3px 6px #00000029;
  opacity: 1;
}

.profileStrengthProgress {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: @primary-color;
  transition: width 1s;
}

.profileStrengthHandle {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #04914D 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #C8CAD1;
  border: 9px solid #FFFFFF;
  border-radius: 50%;
  opacity: 1;
  transition: all 1s;
}

