@import '../../styles/appvar';

.navbar {
  top: 0;
  left: 0;
  right: 0;
  //height: 75px;
  background: #ffffff;
  opacity: 1;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s;
  z-index: 1000;
  border-bottom: 1px solid @light-gray;
  @media (max-width: @screen-sm-max) {
    //height: 140px;
  }
}

.navbarScrolled {
  border-bottom: 1px solid #ecedee;
  box-shadow: 1px 2px 3px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 2px 3px -1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 2px 3px -1px rgba(0, 0, 0, 0.25);
}

.navbarContent {
  height: 75px;
  width: 100%;
  max-width: @candidate-width;
  min-width: @candidate-min-width;
  padding: @app-padding-lg @app-padding-xxl;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
  }

  @media (max-width: @screen-lg-max) {
    padding: @app-padding-lg @app-padding-md;
  }
  @media (max-width: @screen-md-max) {
    display: none;
  }
  @media (max-width: @screen-md-max) {
    max-width: unset;
    min-width: unset;
  }

  :global {
    button {
      padding-left: 11px !important;
      padding-right: 11px !important;
    }
  }
}

.navbarContentMobile {
  display: none;
  //height: 140px;
  padding: @app-padding-lg 16px;
  width: 100%;
  //margin: 0 20px 0 20px;

  .navbarRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    float: right;

    > * {
      border-radius: 4px !important;
      font-size: 14px;
    }
  }

  @media (max-width: @screen-md-max) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.navbarButtons {
  gap: 8px;
  display: flex;
  justify-content: space-between;
  font-family: 'Roboto', sans-serif;
  margin-left: 8px;

  > * {
    border-radius: 4px !important;
    font-size: 14px;
  }

  @media (max-width: @screen-md) {
    margin-left: 20px;
  }
  @media (max-width: @screen-sm) {
    margin-left: unset;
  }
}

.userPopover {
  //background-color: red;
  -webkit-box-shadow: 0 6px 12px -2px rgba(0, 0, 0, 0.5);
  box-shadow: 0 6px 12px -2px rgba(0, 0, 0, 0.5);
}

.navbarUserInfo {
  cursor: pointer;
  position: relative;
  max-width: 300px;
  //margin-left: 16px;
  // padding-top: 10px;
  @media (max-width: @screen-md) {
    margin-left: unset;
    > div {
      :global {
        .ant-btn-circle {
          min-width: unset;
        }
      }
    }
  }
}

.navbarUserName {
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.showLoginContainer {
  margin-top: 170px !important;
  @media (max-width: @screen-md-max) {
    margin-top: 200px !important;
  }
  @media (max-width: @screen-sm-max) {
    margin-top: 200px !important;
  }
}

.showLoginContainerX {
  margin-top: 170px !important;
  @media (max-width: @screen-md-max) {
    margin-top: 294px !important;
  }
  @media (max-width: @screen-sm-max) {
    margin-top: 200px !important;
  }
}

.article {
  margin-top: 75px;
  display: flex;
  justify-content: center;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: @candidate-width;
    min-width: @candidate-min-width;
    padding: 20px 65px 12px 65px;
    @media (max-width: @screen-lg-max) {
      padding: 20px 30px 12px 30px;
    }

    @media (max-width: @screen-sm) {
      padding: 20px 0 12px 0;
    }
  }

  @media (max-width: @screen-md-max) {
    margin-top: 79px;
    padding: 16px;
  }
  //@media (max-width: @screen-sm-max) {
  //  margin-top: 140px;
  //
  //  > div {
  //    padding: 20px;
  //  }
  //}
}

.articleX {
  margin-top: 75px;
  display: flex;
  justify-content: center;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: @candidate-width;
    min-width: @candidate-min-width;
    padding: 20px 65px 12px 65px;
    @media (max-width: @screen-lg-max) {
      padding: 20px 0 12px 0;
    }
    @media (max-width: @screen-sm) {
      padding: 20px 0 12px 0;
    }
  }

  @media (max-width: @screen-md-max) {
    margin-top: 79px;
    padding: 0 16px 16px 16px;
  }
}

.elips {
  white-space: nowrap;
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.elips1 {
  white-space: nowrap;
  width: 180px;

  overflow: hidden;
  text-overflow: ellipsis;
}

.articleSearch {
  margin-top: 140px;
  @media (max-width: @screen-md-max) {
    margin-top: 180px;
  }
}

.articleSearchLogin {
  margin-top: 280px !important;
  @media (max-width: @screen-md-max) {
    margin-top: 180px;
  }
}

.articleBanner {
  margin-top: 240px;
  @media (max-width: @screen-md-max) {
    margin-top: 294px;
  }
  @media (max-width: @screen-sm-max) {
    margin-top: 180px;
  }
}

.navbarBanner {
  height: 50px;
  width: 100%;
  background: @primary-color;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.navbarBannerContent {
  max-width: 1600px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  a:hover {
    color: #9de3c1;
    text-decoration: underline;
  }

  @media (max-width: @screen-xs-max) {
    font-size: 0.8em;
  }
}

.navbarBannerButton:hover {
  color: #9de3c1;
}

.loginOptionBtn {
  width: 180px;
}

footer {
  .footerMain {
    justify-content: center;
    display: flex;
    flex-direction: column;

    @media (max-width: @screen-sm-max) {
      flex-direction: column;
    }
  }

  .logoContainer {
    max-width: 220px;
    // margin-left: -15px;
  }

  .footerContentContainer {
    align-self: center;
    display: flex;
    flex-direction: row;
    //margin: 0 75px;
    max-width: 1600px;
    width: 100%;
    padding: 0 65px;

    > div {
      padding-right: 106px;
    }

    > div:last-child {
      padding-right: 0;
    }

    @media (max-width: @screen-sm-max) {
      //margin: 0 20px;
      > div {
        padding-right: 0;
      }
    }

    @media (max-width: @screen-lg) {
      display: flex;
      flex-direction: column;
      width: 90%;
      > div {
        padding-right: 0;
      }
    }

    .logoContainerView {
      display: flex;
      flex: 2;
      flex-direction: column;

      > div {
        > * {
          margin-bottom: 8px;
        }
      }

      > * {
        // margin-left: 20px;
      }

      @media (max-width: @screen-sm-max) {
        > * {
          margin-left: 0;
          margin-bottom: 40px;
        }

        > *:first-child {
          margin-bottom: 8px;
        }
      }
    }

    .footerMainLeft {
      display: flex;
      flex: 5;
      flex-direction: column;

      .footerContentTop {
        justify-content: space-between;
        width: 100%;
        @media (min-width: 768px) and (max-width: 990px) {
          margin-bottom: 40px;
        }

        display: flex;
        flex-direction: row;

        > div {
          padding-right: 40px;
          display: flex;
          flex-direction: column;
          color: #636c72;

          > * {
            margin-bottom: 20px;
            @media (max-width: @screen-sm-max) {
              margin-bottom: 10px;
            }
          }

          > *:first-child {
            color: black;
            margin-bottom: 28px;
            margin-top: 5px;
          }

          > span:hover {
            cursor: pointer;
            color: @primary-color;
          }
        }

        > div:last-child {
          padding-right: 0;
        }

        @media (max-width: @screen-sm-max) {
          > div {
            margin-bottom: 50px;

            > *:first-child {
              margin-bottom: 10px;
            }
          }
        }
      }

      .socialIcons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: 40px;

        > * {
          margin: 0 10px;
        }

        > div:hover {
          cursor: pointer;
          opacity: 0.6;
        }

        @media (max-width: @screen-lg) {
          margin-top: 5px;
          gap: 10px;
          justify-content: flex-end;
        }
        @media (max-width: @screen-xs) {
          margin-top: 5px;
          gap: 5px;
          justify-content: flex-start;
        }
      }

      .contentColumn {
      }
    }
  }

  .copyrightText {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;

    @media (max-width: @screen-sm-max) {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      margin-bottom: 70px;
    }
  }

  @media (max-width: @screen-lg-max) {
    .footerContentContainer {
      padding: 0 20px;
      width: 100%;
    }
  }
}

@media (max-width: @screen-sm) {
  .footerContentContainer {
    flex-direction: column !important;
  }

  .footerContentTop {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.footerImage {
  width: 100%;
  // margin: 80px 0;
  // background-image: url('https://c360-qa-files.s3.us-east-2.amazonaws.com/files/footer_image.png');
  // background-repeat: repeat;
  // height: 307px;
}

.footerImagemobile img {
  width: 100%;
  margin: 40px 0;
  background-repeat: repeat;
}

.logo {
  cursor: pointer;
  margin-right: 24px;
  @media (max-width: 500px) {
    margin-right: 0;
  }
  @media (max-width: 380px) {
    width: 200px;
  }
}

.logoX {
  cursor: pointer;
  margin-right: 8px;
  @media (max-width: 380px) {
    width: 200px;
  }
}

.searchInputBarSearch {
  flex-grow: 1;

  :global {
    .ant-input {
      background-color: @light-gray !important;
    }

    .ant-input-affix-wrapper {
      background-color: @light-gray !important;
      @media (min-width: @screen-md) {
        border-radius: 0 !important;
      }
    }

    .ant-select-selector input {
      min-height: unset;
    }
  }
}

.searchInputBarSelect {
  min-width: 200px;
  max-width: 200px;
  text-align: center;
  flex-grow: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;

  :global {
    .ant-select-selector {
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;
      background-color: @light-gray !important;

      .ant-select-selection-item {
        justify-content: center;
      }
    }
  }

  @media (max-width: @screen-md-max) {
    min-width: 120px;
    max-width: 120px;
  }
}

.profilename {
  white-space: nowrap;
  overflow: hidden;
  width: 180px;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
}
.profileEmail {
  font-size: 11px;
  color: #000000d9 !important;
}

.recruiterNameItem {
  font-size: 13px;
}

.profileItem1 :hover {
  color: @primary-color !important;
}
.profileItemQuick {
  color: @primary-color !important;
}
.recruiterList {
  padding-top: 20px;
  .recruiterListItem {
    padding-top: 0 !important;
    padding-left: 9px !important;
    padding-bottom: 0 !important;
  }

  :global {
    .ant-menu-item:hover {
      background-color: transparent !important;
    }
  }
}

.userInfo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footerlinks {
  :global {
    .ant-typography:hover {
      color: @primary-color;
    }
  }
}

.footerlinks a {
  text-decoration: none;
}

.contactContent {
  @media (max-width: @screen-lg) {
    display: grid;
    grid-template: 'item item';
  }
  @media (max-width: @screen-sm) {
    display: flex;
    flex-direction: column;
  }
}

.navbarCommonButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.main {
  min-width: 360px;
  //max-width: 99vw;
}

.navbarSubContentMobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hamburgerItems {
  display: flex;
  flex-direction: column;
  margin-left: auto !important;
}

.hamburgerItem {
  margin: 12px 0;
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px;
}

.hamburgerItemsClose {
  display: none;
}

.hamburgerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 40px;

  span {
    svg {
      margin-top: 4px;
    }
  }
}

.bellContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.signinShowIcon {
  padding: 5px;
  background-color: #ebf6f0;
  height: 40px;
  border: none;

  div {
    margin-top: 2px !important;
  }
}

.navDropdown {
  :global {
    li:hover {
      background-color: #f1fcf7 !important;
    }
  }
}

.signinHideIcon {
  padding: 5px;
  background-color: #ebf6f0 !important;
  margin-bottom: -16px;
  height: 56px;
  border: none;
  box-shadow: none !important;
}

.signinHideIcon:hover,
.signinShowIcon:hover,
.signinHideIcon:active,
.signinShowIcon:active,
.signinHideIcon:focus,
.signinShowIcon:focus {
  background-color: #ebf6f0 !important;
}

.signinShowIconContainer {
  background-color: #ebf6f0 !important;
  border-radius: 5px;
  margin-right: 20px;
}

.signupButtonShortcut {
  background-color: #ebf6f0;
  margin: 0px -20px 0 -20px;
  padding: 30px 20px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 420px) {
  .loginOptionBtn {
    width: calc(50vw - 30px);
  }
}

@media (max-width: 380px) {
  .signinShowIconContainer {
    margin-right: 10px;
    margin-left: 10px;
  }

  .loginOptionBtn {
    width: 152px;
  }
}

.userMenu {
  li {
    padding-bottom: 12px !important;
    padding-top: 12px !important;
  }

  :global {
    .ant-dropdown-menu-item:hover {
      background-color: transparent !important;
      color: @primary-color;
    }
  }
}

//@media (max-width: 1366px) {
//  .userNameX {
//    display: none;
//  }
//}

@media (max-width: 991px) {
  .userNameX {
    display: block;
  }
}
