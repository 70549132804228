@import './appvar';

.borderedPrimaryButton {
  border-color: #1FAA77;
  color: #1FAA77;
}
.borderedPrimaryButton:hover {
  border-color: #1FAA77;
  color: white !important;
  background-color: #1FAA77;
}

.borderedPrimaryButtonBlack {
  background-color: #212C3A !important;
  color: white !important;
  border-color: #212C3A !important;
}

.borderedPrimaryButtonGreen {
  background-color: @primary-color;
  color: white;
  border-color: @primary-color;
}

.borderedPrimaryButtonGreenBlock {
  border-color: @primary-color;
  color: @primary-color;
}

.borderedPrimaryButtonGreenBlock:hover {
  border-color: @primary-color;
  color: white !important;
  background-color: @primary-color;
}
