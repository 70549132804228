@import '../../styles/appvar.less';

.article {
  margin-top: 130px;
  display: flex;
  justify-content: center;
  padding: 0 20px;

  > div {
    width: 100%;
  }
}

.articleedit {
  margin-top: 130px;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  padding-left: 8%;
  padding-right: 8%;

  > div {
    width: 100%;
  }
}

.coverImageHolder {
  background: #fafafa;
  position: relative;
  //height: 260px;
  min-height: 160px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 29px;
  color: #fafafa;
  border: 2px dashed #e0dfdf;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  > img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: @screen-md-max) {
    > img {
      object-fit: cover;
      object-position: bottom;
    }
  }
  @media(max-width: @screen-xl-min) {
    min-height: 125px;
  }
  @media (max-width: 822px) {
    min-height: 60px;
  }
  @media (max-width: 470px) {
    min-height: 20px;
  }
}

.bgcontainer {
  background-color: white;
}

.coverUploader {
  :global {
    .ant-upload.ant-upload-select {
      display: block;
    }
  }
}

.coverBorder {
  border: 1px solid #ecedee !important;
}
.emptyCoverHolder {
  margin-top: 20px;
  min-height: 200px;
  position: relative;
  background-color: #fafafa;

  @media(max-width: @screen-xl-min) {
    min-height: 160px;
  }
  @media (max-width: 822px) {
    min-height: 120px;
  }
  @media (max-width: 470px) {
    min-height: 90px;
  }
}
.emptyCoverLogoHolder {
  //top: 200px !important;
  //left: 25% !important;
}
.logoHolder {
  position: absolute;
  height: 180px;
  width: 180px;
  left: 11%;
  top: 56%;
  //bottom: px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 17px;
  color: #ffff;
  border-radius: 50%;
  background: #fff;
  border: 2px dashed #e0dfdf;
  box-sizing: border-box;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  @media(max-width: @screen-xl-min) {
    width: 160px;
    height: 160px;
    top: 40%
  }

  @media(max-width: @screen-lg-min) {
    width: 150px;
    height: 150px;
  }

  @media (max-width: @screen-sm-max) {
    top: 50%;
    height: 130px;
    width: 130px;
  }
  @media (max-width: 822px) {
    height: 120px;
    width: 120px;
  }
  @media (max-width: 736px) {
    width: 105px;
    height: 105px;
    top: 50%;
  }
  @media(max-width: @screen-sm-min) {
    width: 90px;
    height: 90px;
    top: 50%;
  }
  @media (max-width: 470px) {
    width: 85px;
    height: 85px;
    top: 40%;
  }

  @media (max-width: 390px) {
    left: 5%;
  }
}

.logoContent {
  width: 178px;
  height: 178px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 822px) {
    width: 150px;
    height: 150px;
  }
  @media (max-width: 736px) {
    width: 100px;
    height: 100px;
  }
  @media (max-width: 470px) {
    width: 80px;
    height: 80px;
  }

  > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.headerHolder {
  position: relative;
}

.logBorder {
  border: 4px solid #ecedee !important;
}

.editButton {
  position: absolute;
  height: 42px;
  width: 42px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  border-radius: 50%;
  background: #ffffff;
  border: 2px solid #e0dfdf;
  box-sizing: border-box;
}

.editIcon {
  color: #9d9d9f;
  font-size: 20px;
}

.editPositionAvatar {
  right: 10px;
  bottom: 5px;
  @media (max-width: 736px) {
    right: -10px;
    bottom: 1px;
  }
}

.editPositionCover {
  right: 10px;
  bottom: 10px;
}

.coverUploadText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadInfoText {
  font-size: 14px;
  text-align: center;
  color: #202124;
  width: 110px;
  line-height: 20px;
  font-weight: bold;
}

.uploadIcon {
  text-align: center;
  color: #202124;
  font-size: 20px;
}

.fileTypeText {
  font-size: 12px;
  text-align: center;
  color: #9999a3;
  line-height: 20px;
}

.profileBody {
  margin-top: 90px;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  margin-bottom: 48px;

  > div {
    background-color: #ffffff;
    width: 100%;
    //max-width: 1040px;
  }
}

.titleBold35 {
  font-size: 35px !important;
  letter-spacing: 0.35px;
  color: #212121;
  font-weight: bold;
  text-align: left;
  padding: 0 20px;
  margin-top: 100px;

  h1 {
    margin-bottom: 0.5em;
  }

  @media (max-width: @screen-sm) {
    margin-top: 80px;
  }
}

.recruiterCard {
  //max-width: 1031px;
  width: 100%;
  margin-bottom: 16px;
  box-shadow: none !important;
}
.benifitCard {
  width: 100%;
  box-shadow: none !important;
}

.recruiterContent {
  font-size: 14px;
  letter-spacing: 0.14px;
  text-align: justify;
  color: #212121;
  margin-top: 0;
  p {
    margin-top: 0;
  }
}

.numberView {
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 40px;
  height: 30px;
  display: flex;
  background-color: #0e1f35;
  color: #ffffff;
  border-radius: 5px;
  opacity: 1;
}

.rowItem {
  display: flex;
  flex-direction: row;

  > * {
    margin-left: 12px;
  }

  > *:first-child {
    margin-left: unset;
  }
}

.rowItemContainer {
  display: flex;
  flex-direction: row;
  margin-top: 25px;

  > * {
    margin-left: 20px;
  }

  > *:first-child {
    margin-left: unset;
  }
}

.infoText {
  text-align: left;
  font: normal normal normal 20px/20px Roboto;
  letter-spacing: 0.2px;
  color: #5b6272;
  opacity: 1;
}

.infoTitle {
  text-align: left;
  font: normal normal bold 17px/27px Roboto;
  letter-spacing: 0.17px;
  color: #202124;
  opacity: 1;
}

.infoHolder {
  display: flex;
  flex-direction: row;
  //flex-wrap: wrap;
  //max-width: 1031px;
  width: 100%;
  margin-bottom: 25px;
  justify-content: space-between;
  //padding: 0 20px;

  > div {
    margin-right: 16px;
    min-width: 190px;
  }

  > div:last-child {
    margin-right: 0;
  }

  @media (max-width: @screen-xl) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
.infoHolderContent {
  display: flex;
  flex-direction: row;
  //flex-wrap: wrap;
  //max-width: 1031px;
  width: 100%;
  justify-content: space-between;
  //padding: 0 20px;
  gap: 10px;

  > div {
    margin-right: 16px;
    min-width: 190px;
  }

  > div:last-child {
    margin-right: 0;
  }

  @media (max-width: @screen-sm-max) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.infoIcon {
  margin-top: 1px;
}

.infoContent {
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  line-height: 1.5715;
}

.socialHolder {
  display: flex;
  //position: absolute;
  flex-direction: row;
  margin-top: -80px;
  margin-bottom: 60px;
  //max-width: 1031px;
  width: 100%;
  padding-right: 20px;
  justify-content: flex-end;

  > * {
    margin-left: 13px;
    cursor: pointer;
  }

  > *:first-child {
    margin-left: unset;
  }

  @media (max-width: @screen-sm) {
    justify-content: flex-start;
    margin-top: 2px;
    margin-bottom: 20px;
    padding-left: 20px;
  }
}

.actionBar {
  display: flex;
  //max-width: 1031px;
  width: 100%;
  margin-top: 23px;
  //justify-content: flex-end;
  flex-direction: row;
}

.recruiterActionBar {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  // padding-right: 90%;
  @media (max-width: @screen-sm) {
    display: block;
  }
}

.tipsright {
  float: left;
  padding-right: 10px;

  :global(.ant-btn-primary) {
    background-color: #fdfcda;
    border-color: #fdfcda;

    &:hover {
      background-color: #fcfac0 !important;
      border-color: #fcfac0 !important;
    }

    @media (max-width: @screen-md-max) {
      margin-bottom: 10px;
    }
  }
}
.hotTips {
  margin-left: 10px;
  font-size: 19px;
  font-weight: 600;
}

.recruiterTitle {
  font-size: 20px;
  font-weight: bold;
}

.recruiterTitle1 {
  margin-top: 12px;
  font-size: 19px !important;
}

.recruiterTitleContainer {
  margin-top: 0;
}

.recruiterViewHolder {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.recruiterView {
  width: 80%;
  @media (max-width: @screen-sm-max) {
    width: 100%;
  }
}

.recruiterProfile {
  background-color: #ffffff;
  border-radius: 5px;
}

.socialMediaIcons {
  display: flex;
  flex-direction: row;
}

.socialMediaIcon {
  margin: 2px 8px 0 0;
}

.socialMediaIcon:hover {
  cursor: pointer;
}

.searchContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 54px 5px 36px 5px;
  @media (max-width: @screen-lg-min) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.searchBar {
  max-width: 746px;
  width: 60vw;

  @media (max-width: @screen-lg-max) {
    width: 56vw;
  }

  @media (max-width: @screen-md-max) {
    width: 490px;
  }
  @media (max-width: @screen-sm-max) {
    width: 500px;
  }
  @media (max-width: @screen-xs-max) {
    width: 310px;
  }
}

.allJobsList {
  margin: 30px 0;
}

.seeMoreBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.searchContainerCount {
  @media (max-width: @screen-lg-min) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 12px;
  }
}

.relatedJobs {
  margin-right: 10px;
}

.continercreate {
  padding: 4%;
}
.planbox {
  height: 150px;
  border-radius: 10px;
  font-size: 16px;
  width: 40%;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  padding: 15px;
  align-items: center;
  border: 1px solid #d9d8d8;

  box-shadow: 0 3px 6px #00000029;
  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 30px;
  }
}
.topsavebutton {
  float: right;
  @media (max-width: 577px) {
    display: none;
  }
}
.bannerDesc {
  text-align: center;

  color: rgb(91, 98, 114);
  font-weight: 500;
  @media (max-width: 470px) {
    font-size: 14px;
  }
}
