@import '../../../styles/appvar';

.textTag {
  padding: 10px;
  background-color: #f6f6f6;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}
