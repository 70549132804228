.blogimage {
}

.blogimage img {
  width: 100%;
  height: 174px;

  @media (max-width: 900px) {
    height: 120px;
  }

  @media (max-width: 800px) {
    height: 100px;
  }

  @media (max-width: 700px) {
    height: 80px;
  }
  @media (max-width: 650px) {
    height: auto;
  }
  //@media (max-width: 500px) {
  //  height: 180px;
  //}
}

.blogImgContainer {
  width: 100%;
  display: block;
  padding-top: 10px;
}

.blogItemContainer {
  display: flex;
  flex-direction: row;
  padding: 5px;

  @media (max-width: 650px) {
    flex-direction: column;
  }
}

.blogItem {
  overflow: hidden;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  margin: 5px;
  max-width: 352px;
}

.blogTitle {
  text-align: left;
  font-size: 19px;
  letter-spacing: 0.19px;
  color: #050931;
  opacity: 1;
}

.blogDescriptionContainer {
  text-align: left;
  font-size: 12px;
  font-weight: 200;
  letter-spacing: 0.12px;
  color: #848998;
  opacity: 1;
  margin-top: 10px;

  height: 45px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blogTitleContainer {
  height: 48px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  text-align: left;
  font-size: 19px;
  letter-spacing: 0.19px;
  color: #050931;
  opacity: 1;
}

.linkbutton {
  background-color: rgba(80, 80, 80, 0.096);
  margin: 10px;
  display: flex;
}

.blogItemText {
  padding: 10px 20px 20px 20px;
}
