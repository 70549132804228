@import '../../styles/appvar';

.inputPlaceholderPrefix {
  padding: 0 12px;
  align-items: center;
  display: flex;
}

.searchLocation {
  height: 65px;

  :global {
    .ant-select-selector {
      height: 65px !important;
      align-items: center !important;
      padding-left: 68px !important;
    }

    input {
      height: 65px !important;
      padding-left: 50px !important;
    }
  }
}

.locationSearchIcon {
  position: absolute;
  top: 20px;
  left: 18px;
}

.searchIcon {
  padding-left: 14px;
}

.searchIconX {
  padding-left: 20px;
}

.homeTopSectionTabs {
  margin-top: 20px;
  max-width: 540px;
  @media (max-width: @screen-md-max) {
    max-width: unset;
  }
}

.tabPaneText {
  font-size: 23px;
  font-weight: 400;

  @media (max-width: @screen-sm-max) {
    font-size: 20px;
  }
}

.tabPaneText:hover {
  font-weight: 500;
}

.tabPaneTextX {
  .tabPaneText;

  @media (max-width: @screen-sm-max) {
    font-size: 18px;
  }
}

.tabPaneTextXActive {
  .tabPaneText;
  color: @primary-color;
  @media (max-width: @screen-sm-max) {
    font-size: 18px;
  }
}

.tabPaneText {
  font-size: 20px;
  font-weight: 400;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: -8px;
}

.searchForm {
  .btnText {
    padding: 0 20px;
  }

  :global {
    .ant-input-clear-icon {
      font-size: @app-font-size-text2;
    }

    .ant-select-arrow {
      font-size: @app-font-size-text2;
    }

    .ant-input, .ant-select {
      font-size: 20px !important;
      font-weight: 500 !important;
    }

    .ant-input-affix-wrapper, .ant-select-selector {
      border: none !important;
      border-radius: 8px !important;
    }
  }
}

.btnLarge {
  font-size: 24px;
  padding: 0 32px;
  border-radius: 8px;
  @media (max-width: @screen-md-max) {
    width: 100%;
  }
}

.searchBtn {
  .btnLarge !important;
}

.topSectionLeft {
  content: ' ';
  background: transparent url('../../public/home2/home-background1.svg') 0 0 no-repeat padding-box;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  @media (max-width: @screen-sm-min) {
    display: none;
  }
}

.topSectionRight {
  content: ' ';
  background: transparent url('../../public/home2/home-background1.svg') 0 0 no-repeat padding-box;
  background-size: cover;
  background-position-x: right;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  @media (max-width: @screen-sm-min) {
    display: none;
  }
}

.topRightDiv {
  height: 512px;
  width: 568px;
  position: relative;
  background: transparent url('../../public/home2/home-image1.svg') 0 0 no-repeat padding-box;
  background-size: cover;
  @media (min-width: @screen-lg-min) and (max-width: 1280px) {
    margin-left: -280px;
    margin-right: -120px;
    margin-bottom: -200px;
    margin-top: -200px;
    transform: scale(0.75);
  }

  @media (min-width: 1280px) and (max-width: 1320px) {
    margin-left: -280px;
    margin-right: -65px;
    margin-bottom: -200px;
    margin-top: -200px;
    transform: scale(0.75);
  }

  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    margin-left: -112px;
    margin-right: -58px;
    margin-bottom: -200px;
    margin-top: -200px;
    transform: scale(0.8);
    padding-left: 100px;
    padding-top: 100px;
  }

  @media (max-width: @screen-sm-max) {
    background-image: unset;
    height: auto;
    width: 100%;
  }

  //@media (max-width: 500px) {
  //  transform: scale(0.75);
  //  margin-left: -120px;
  //  margin-right: -120px;
  //  margin-bottom: -40px;
  //  margin-top: -30px;
  //}

  //@media (max-width: 400px) {
  //  transform: scale(0.65);
  //  margin-left: -220px;
  //  margin-right: -220px;
  //  margin-bottom: -60px;
  //  margin-top: -50px;
  //}
}

.careerText {
  background: linear-gradient(90deg, #00A3E6 0%, #00A1B3 24%, #009F87 48%, #009E68 69%, #009E54 87%, #009E4E 100%, #00A5A0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.swiperOverride {
  :global {
    .swiper-pagination-bullet {
      width: 17px;
      height: 17px;
      /* UI Properties */
      background: #ECEFF1 0% 0% no-repeat padding-box;
      box-shadow: inset 0px 3px 6px #00000029;
      opacity: 1;
      position: relative;
      @media (min-width: @screen-xl) {
        display: none;
      }
    }

    .swiper-pagination-bullet-active::after {
      content: ' ';
      width: 11px;
      height: 11px;
      /* UI Properties */
      background: #04914D 0% 0% no-repeat padding-box;
      box-shadow: inset 0px 3px 6px #00000029;
      opacity: 1;
      top: 3px;
      position: absolute;
      left: 3px;
      border-radius: 50%;
    }
  }
}

.latestSwiper {
  @media (width: 1440px) {
    margin-left: 15px;
  }
}

.profileBanner {
  background: #F8F9FD url('../../public/home2/boy.png') 0 0 no-repeat padding-box;
  background-position: left bottom;
  border-radius: 20px;
  min-height: 362px;
}

.testimonialsSlider {
  max-width: 100%;
  @media (min-width: @screen-lg-min) {
    width: calc(100% - 150px);
    max-width: 1278px;
  }
}

.stepBackground {
  background: transparent linear-gradient(249deg, #1FAA77 0%, #5CCD97 100%) 0 0 no-repeat padding-box;
  border-radius: 2px;
}
