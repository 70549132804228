@import '../../styles/appvar';

.recruiterSider {
  z-index: 6;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  min-height: 100vh;
  ////width: 247px;
  //min-width: 60px !important;
  // background-color: #0e1f35 !important;
  //margin-top: 70px;
  padding-top: 70px;
  position: fixed !important;
  height: 100vh;
  overflow: visible;
  //overflow-y: visible;
  background-color: #0e1f35 !important;

  :global {
    .ant-layout-sider-children {
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
}

.siderMenuCollapseBtn {
  position: absolute;
  height: 45px;
  width: 45px;
  left: 100%;
  top: 98.5px;
  z-index: 8;
  transform: translateX(-50%);
  cursor: pointer;
}

.siderContentContainer {
  margin: 50px 20px 35px 20px;
  background-color: white;
  position: relative;
}

.recruiterNavBar {
  display: flex;
  background-color: #0e1f35 !important;
  // background-color:black;
  height: 70px;
  min-height: 70px !important;
  padding-inline: 16px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.siderMenu {
  background: #0e1f35 !important;
  width: 100% !important;

  :global {
    .ant-menu-item {
      > div {
        margin-right: @app-padding-lg !important;
      }
    }

    .ant-menu-title-content {
      > span > div {
        margin-right: @app-padding-lg !important;
      }
    }
  }

  > li {
    width: 100% !important;
  }
}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: 21px;
  height: 30px;
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
}

.mainMenu {
  margin-top: 15px;
}

.recruiterMenuSider {
  //z-index: 2;
  border: 1px solid #ecedee !important;
  background-color: #ffffff !important;
  opacity: 1 !important;
  margin-top: 70px !important;
  position: fixed !important;
  overflow: scroll !important;
  overflow-y: hidden !important;
  right: 0 !important;
  height: calc(100vh - 70px) !important;
  overflow-x: hidden !important;
}

.recruiterName {
  line-height: 1.25;
  color: black;
  font-weight: bold;
  margin-bottom: 3px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

.createBtn {
  width: 256px;
  height: 53px;
  letter-spacing: 0.09px;
}

.createBtnCollapsed {
  width: 40px;
  height: 40px;
  padding: 3px 3px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.btnHolder {
  padding: 20px 20px 20px 20px;
  background-color: #f7f7f9;
}

.btnHolderCollapsed {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: #f7f7f9;

  :global {
    button {
      width: 40px !important;
      font-size: 18px;
    }
  }
}

.selectBoxHolder {
  padding: 20px 20px 20px 20px;
}

.createRecruiterContainer {
  padding-bottom: 20px;
  gap: 20px;
}

.recruiterItemHolder {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border: 1px solid #ecedee;
  opacity: 1;
  cursor: pointer;
}

.selectedRecruiter {
  background-color: #f7f7f9;
}

.recruiterItemHolder:hover {
  .selectedRecruiter;
}

.recruiterIconHolder {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
}

.itemRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.itemSubName {
  letter-spacing: 0.12px;
  color: #009e60;
  text-transform: capitalize;
  opacity: 1;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.itemSubNameGreen {
  color: #848998;
}

.itemrolename {
  white-space: initial;
}

.contentLayout {
  position: relative;
  padding: 70px 24px 0 24px;
  min-height: calc(100vh - 20px);
  background-color: #e5ecf8;

  //@media (max-width: @screen-md-max) {
  //  margin: 70px 20px 0 75px;
  //}

  @media (max-width: @screen-md-max) {
    margin: 0 20px 0 75px;
  }
}

.recruiterNavbarContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0e1f35;
}

.recruiterNavbarUserInfo {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  max-width: 300px;
  overflow: hidden;
}

.navbarAvatar {
  margin-left: 16px;
  margin-right: 8px;
  flex-grow: 0;
  flex-shrink: 0;
}

.navbarUserName {
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: white;
}

.rightSiderCollapseBtn {
  position: absolute;
  bottom: 16px;
}

.paddingTop {
  padding-top: @app-padding-xl;
}

.menuIcon {
  fill: white;
  color: white;
  margin-right: 5px;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
}

.siderContent {
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
  overflow-y: auto;
}

.itemAvatar {
  flex-grow: 0;
  flex-shrink: 0;
}

.selectedAvatar {
  flex-grow: 0;
  flex-shrink: 0;
  border: 3px solid @primary-color;
}

.sidebarIcon {
  padding-left: 4px;
  font-weight: 500;

  svg {
    width: 20px;
    height: 40px;
  }
}

.c360 {
  :global {
    .ql-editor {
      p {
        margin-bottom: 16px;
      }
    }
  }
}
.menuTitle1 {
  margin-top: 0;
  font-size: 19px !important;
}
.needhelp {
  position: absolute;
  bottom: 10px;
  right: 50px;
  color: white;
  text-decoration: underline;
}
.needhelpIcon {
  position: absolute;
  bottom: 10px;
  /* right: 50px; */
  font-size: 30px;
  justify-content: center;
  display: flex;
  width: 100%;
  color: white;
  text-decoration: underline;
}
